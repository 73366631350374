.header-sc {
    position: fixed;
    width: 100%;
    top: 0;
    background: rgb(0 0 0 / 10%);
    z-index:2;
   
}
.change-header-bg{
    /* background-image: linear-gradient(to right, #065273,#065273,#065273, #306c8c,#306c8c,#306c8c,#306c8c, #4f87a5, #6ca2bf, #8abfda, #8cc5e2, #8dcaea, #8fd0f2, #75bfe9, #59aedf,#59aedf, #3a9dd6, #3a9dd6, #008ccd); */
    background-image: linear-gradient(to right, rgba(1, 22, 72, 0.7), rgba(138,192,219,.7), rgba(23, 135, 190, 0.7));
    backdrop-filter:blur(7px);
}
.logo-sc {
    padding: 0;
    align-self: center;
}
.contact-head {
    display: flex;
    list-style: none;
    gap: 5px;
    padding-left: 0;
    margin-bottom: 12px;
}
.contact-head span{
    color: #FFF;
    font-size: 13px;
    line-height: 24px;
    margin-left: 0px;
}

.contact-head span a{
    color: #fff;
}
.header-btn,.header-btn:hover{
    color: #0098DB;
    font-size: 14px;
    line-height: 24px;
    border-radius: 16px;
    background: #FFF; 
    padding: 2px 24px; 
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    margin-bottom: 4px;
}
.header-btn:last-child{
    margin-right: 0;
}
.header-main {
    padding: 4px 0;
    display: flex;
    align-items: start;
    justify-content: space-between;
}
.links-header {
    display: flex;
    align-items: end;
    gap: 60px;
}
.menu-sc .navbar {
    padding: 0;
}
.menu-sc .navbar-nav a.nav-link,.menu-sc .navbar-nav a.nav-link:hover,.menu-sc .navbar-nav li.nav-link,.menu-sc .navbar-nav li.nav-link:hover {
    color: #FFF;
    font-size: 17px;
    line-height: normal;
    padding: .3rem .8rem;
    border-bottom: 1px solid transparent;
}
.menu-sc .navbar-light .navbar-nav .nav-link:focus,.menu-sc  .navbar-light .navbar-nav .nav-link:hover{
    color: #fff;
}
.menu-sc .navbar-nav a.nav-link:hover,.menu-sc .navbar-nav li.nav-link:hover{
    border-bottom: 1px #89dbfa solid;
}
.links-header > div {
    text-align: right;
}
.HumbargerIconDiv{
    display: none;
}
.HumbargerIcon {
    fill: #fff;
    font-size: 30px;
}
.nav-link > div{
    display: none;
    background: #fff;
    width: 100%;
    position: absolute;
    left: 0;
    text-align: left;
    animation: menu-animation .4s ;
    -webkit-animation: menu-animation .4s;
    height: fit-content;
    top: 73px;
    padding: 0;
    border-bottom: 1px solid #d7d7d7;
}
.nav-link:hover > div{
    display: block;
}
.header-sc .col,.header-sc .navbar{
    position: static;
}
.inner-header-links .active{
    display: block;
    color: green;
}
.inner-header-links  div{
    display: none;
}
.inner-header-links li{
    margin:0;
    width: 335px;
}
.inner-header-links .active div{
    display: block;
    font-size: 25px;
}
.hover-menu .container{
    position: relative;
}
.inner-header-links .active a{
    color: #3366CC;
    box-shadow: 3px 9px 23px 0px rgba(0, 0, 0, 0.12);
}
.inner-header-links li a:hover{
    text-decoration: none;
}
.inner-header-links{
    list-style: none;
    padding-left: 0;
    border-right: 1px solid #d7d7d7;
    padding: 2rem 1rem 10rem 0;
    margin-bottom: 0;
}
.inner-header-links li a{
    color: #575757;
    font-family: Medium;
    font-size: 18px;
    line-height: normal;
    padding: 10px 18px;
    width: 100%;
    cursor: pointer;
    display: block;
}
.inner-header-content{
    width: 60%;
    position: absolute;
    top: 50%;
    right: 0;
    background: #fff;
    transform: translateY(-50%);
}
.inner-header-content p {
    color: #575757;
    font-size: 17px;
    line-height: 25px;
    margin: 25px 0;
}
.mobile-menu-sc ul a ul{
    display: none;
    list-style: disc;
}
.mobile-menu-sc ul li.active a ul{
    display: block;
    animation: menu-animation .4s ;
    -webkit-animation: menu-animation .4s;
}
.inner-header-links li a.common-blue-btn{
    width: fit-content;
    color: #fff;
}
@keyframes menu-animation{
    0% {
        -webkit-transform: translateY(30px);
        transform: translateY(30px);
      }
      100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
}

.MailTextShowHide{
    display: inline-block;
}

.contact-head-link span a{
    display: none;
}
.contact-head-link span h6{
    display: inline-block;
    font-size: .8rem;
}

@media(min-width:100px) and (max-width:767.90px){
    .MailTextShowHide{
        display: none;
    }

    .contact-head-link span a{
        display: block;
    }

    .contact-head-link span h6{
        display: none;
        font-size: .8rem;
    }
}

@media(min-width:992px) and (max-width:1199.90px){
    .links-header{
        gap:0px;
    }
    .inner-header-content{
        width: 55%;
    }

    .menu-sc .navbar-nav a.nav-link,.menu-sc .navbar-nav a.nav-link:hover,.menu-sc .navbar-nav li.nav-link,.menu-sc .navbar-nav li.nav-link:hover {
        padding: .3rem .7rem;
        font-size: 15px;
    }
}
@media(min-width:768px) and (max-width:991.90px){
    .links-header{
        gap: 0;
        flex-basis: calc(100% - 50%);
        align-items: end;
        justify-content: end;
    }
    .contact-head{
        gap: 15px;
    }
    .contact-head li img,.contact-head li span{
        display: inline-block;
        justify-content: end;
    }
    .menu-sc .navbar-nav a.nav-link, .menu-sc .navbar-nav a.nav-link:hover,
    .menu-sc .navbar-nav li.nav-link, .menu-sc .navbar-nav li.nav-link:hover{
        margin-right: 0px;
        margin-bottom: 12px;
        font-size: 15px;
        padding: .3rem 0.5rem;
    }
    .menu-sc .navbar-nav a.nav-link:last-child{
        margin-right: 0;
    }
    .logo-sc {
        flex-basis: 48%;
    }
    .contact-head li {
        display: flex;
        align-items: center;
    }
    .menu-sc .navbar{
        margin-left: 10px;
    }
    .header-btn, .header-btn:hover{
        padding: 5px 15px;
        margin-bottom: 12px;
    }
    .inner-header-links li{
        width: 100%;
    }
    .inner-header-links{
        width: 70%;
        padding: 1rem 1rem 1rem 0;
    }
    .inner-header-links li a{
        font-size: 15px;
        padding: 8px 18px;
    }
    .inner-header-content p{
        font-size: 15px;
    }
}

@media(max-width:991.90px){
    .menu-sc {
        display: none;
    }
}

@media(max-width:767px){

    .HumbargerIconDiv {
        display: inline-block;
    }
    .links-header{
        gap: 5px;
    }
    .mobile-menu-sc{
        position: fixed;
        height: 100%;
        width: 320px;
        left: auto;
        top: 0;
        overflow: auto;
        right: 0;
        background-image: linear-gradient(to right, #1a529b , #000b18);
        padding: 0;
        margin: 0;
        z-index: 3;
        transition: all 0.6s ease-in-out;
        border-left: solid 2px #fff;
    }
    #overlay{
        background-color: rgba(0, 0, 0, .4);
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 3;
    }
    .mobile-menu-links{
        list-style: none;
        margin-top: 3rem;
        padding: 0 25px;
    }
    .mobile-menu-links li ,.mobile-menu-links a,.mobile-menu-links li a:hover{
        color: #fff;
        font-family: SemiBold;
        font-size: 1.2rem;
        cursor: pointer;
    }
    .mobile-menu-links li {
        padding: 7px 0;
        border-bottom: 1px solid #268cbd;
        position: relative;
    }
    .mobile-menu-links a ul li a{
        font-size: .9rem;
        font-family: Light;
    } 
    .mobile-menu-links a ul li a:hover{
        font-size: .9rem;
        font-family: Light;
        color: #76f2ff;
    } 
    
    .clickmenu:after{
        content: "";
        background-image: url('data:image/svg+xml,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg" style="fill: %23fff;"><path d="M464 256c0-114.87-93.13-208-208-208S48 141.13 48 256s93.13 208 208 208 208-93.13 208-208zm-212.65 91.36a16 16 0 01-.09-22.63L303.58 272H170a16 16 0 010-32h133.58l-52.32-52.73A16 16 0 11274 164.73l79.39 80a16 16 0 010 22.54l-79.39 80a16 16 0 01-22.65.09z"></path></svg>');
        fill: #fff;
        width: 20px;
        height: 20px;
        display: inline-block;
        position: absolute;
        right: 0;
        top: 8px;
    }
    .clickmenu.active:after{
        content: "";
        background-image: url('data:image/svg+xml,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg" style="fill: %23fff;"><path d="M256 464c114.87 0 208-93.13 208-208S370.87 48 256 48 48 141.13 48 256s93.13 208 208 208zm-91.36-212.65a16 16 0 0122.63-.09L240 303.58V170a16 16 0 0132 0v133.58l52.73-52.32A16 16 0 11347.27 274l-80 79.39a16 16 0 01-22.54 0l-80-79.39a16 16 0 01-.09-22.65z"></path></svg>');
    }
    .clickmenu ul li {
        border-bottom: none;
        padding: 3px 0 3px 5px;
        font-size: 15px;
        color: #fff;
    }
    .clickmenu ul li a,.clickmenu ul li a:hover{
        color:#fff;
        text-decoration: none;
    }
    .close-icon {
        font-size: 18px;
        position: absolute;
        right: 15px;
        top: 15px;
        color: #fff;
    }
    .header-main{
        padding: 0;
    }
}
@media(min-width:481px) and (max-width:767.90px){
    .logo-sc {
        flex-basis: 50%;
    }
}
@media(min-width:320px) and (max-width:480.90px){
    .contact-head{
        gap: 0;
        margin-bottom: 0;
        width: 100%;
        text-align: right;
        float: right;
        justify-content: end;
    }
    .contact-head li a{padding-right: .8rem;}
    .contact-head span{
        font-size: 12px;
    }
    .logo-sc {
        flex-basis: 50%;
    }
    .header-btn, .header-btn:hover {
        font-size: 13px;
        padding: 1px 14px;
        margin-right: 7px;
        margin-bottom: 7px;
    }
.links-header{
    width: 50%;
    text-align: right;
}
.links-header > div {
   width: 100%;
  }
}


@media(min-width:320px) and (max-width:359.90px){
    .header-btn, .header-btn:hover {
        font-size: 12px;
        padding: 2px 7px;
        margin-right: 7px;
        margin-bottom: 7px;
    }
}
