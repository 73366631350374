.future-img{
    position: sticky;
    top: 100px;
}
.future-content{
    padding-left: 40px;
}
.future-content div{
    position: relative;
}
.future-content div:after{
    content: "";
    width: 165px;
    height: 1px;
    background-color: #B1B1B1;
    position: absolute;
    bottom: 0;
    left: 0;
}
.future-content label{
    color: #000;
    font-family: SemiBold;
    font-size: 24px;
    line-height: 27px;
}
.future-content p{
    color:#575757;
    font-family: Light;
    font-size: 17px;
    line-height: 24px;
    padding-bottom:4rem;
    margin-bottom: 5rem;
}
@media(min-width:768px) and (max-width:991.90px){
    .future-content {
        padding-left: 0;
    }
    .future-content p{
        padding-bottom: 25px;
        margin-bottom: 25px;
    }
}
@media(min-width:481px) and (max-width:767.90px){
    .future-content {
        padding-left: 0;
    }
    .future-content p{
        padding-bottom: 25px;
        margin-bottom: 25px;
        font-size: 15px;
    }
    .future-img{
        width: 50%;
        margin: 0 auto 1rem;
    }
    .future-content label{
        font-size: 22px;
    }
}
@media(min-width:320px) and (max-width:480.90px){
    .future-content {
        padding-left: 0;
    }
    .future-content p{
        padding-bottom: 20px;
        margin-bottom: 20px;
        font-size: 14px;
    }
    .future-img{
        width: 70%;
        margin: 0 auto 1rem;
    }
    .future-content label{
        font-size: 20px;
    }
}