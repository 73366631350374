.DivMarginLeft{
    margin-left: 10%;
    width: 80%;
    margin-top: 1rem;
    margin-bottom: 4rem;
}
.LatsBox{
    width: 100%;
    margin: 0;
    padding: 0;
    float: left;
    background: url("./../../Images/lestTalkBoxBG.png") no-repeat;
    border-radius: 1rem;
}
.LatsBox ul{
    width: 100%;
    padding: 0;
    margin: 0;
}
.LatsBox ul li{
    width: 100%;
    color: #000;
    list-style-type: none;
}
.LatsBox ul li:first-child{
    font-size: 1rem;
    padding: 1.5rem 0 1.5rem 1rem;
    margin: 0;
}

.LatsBox ul li:nth-child(2){
    font-size: 1.8rem;
    padding:1rem 2rem 1rem 1rem;
    margin:0;
    font-family: SemiBold;
    line-height: 2rem;
}
.LatsBox ul li:nth-child(3){
    font-size: 1.1rem;
    padding: 2rem 0 2rem 1rem;
    font-family: SemiBold;
    margin: 0;
    color: #0098DB;
}

@media(min-width:768px) and (max-width:991.90px){
    .DivMarginLeft {
        margin-left: 0;
        width: 100%;}
    .LatsBox ul li:nth-child(2){ font-size: 1.6rem; }
    .PeopleSayTable {width: 100%;  margin: 5rem 0;}
}

@media(min-width:320px) and (max-width:767.90px){
    .LatsBox{
        background-size: 100%;
        margin-top: 1rem;
    }

}