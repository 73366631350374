.glance-ul {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
    float: right;
    margin-top: -4rem;
}
.glance-ul li {
    display: inline-block;
    color: #000;
    font-family: SemiBold;
    font-size: 18px;
    font-style: normal;
    line-height: 32px;
    text-transform: capitalize;
    padding: 6px 13px;
    margin-left: 30px;
    border-bottom: 1px solid #BDBDBD; 
    cursor: pointer;
}
.glance-ul li.active{
    border-bottom: 1px solid #1970CA; 
    cursor: unset;
}
.glance-main {
    float: left;
    width: 100%;
}
.glance-content{
    display: flex;
}
.glance-content > div{
    flex:1;
    padding: 2rem;
}
.glance-content h3{
    color: #000;
    font-family: SemiBold;
    font-size: 32px;
    line-height: 27px; 
    margin-bottom: 25px;
}
.glance-content p{
    color:  #575757;
    font-family: Light;
    font-size: 18px;
    line-height: 27px; 
}
.glance-content-inner{
    margin-top: 60px;
}
.glance-content label{
    color: #000;
    font-family: SemiBold;
    font-size: 24px;
    line-height: 27px; 
}
.glance-content label span{
    color: #0098DB; 
}
.glance-content > div img {
    margin-left: auto;
}
.innovators-main-content h3{
    color: #000;
    font-family: SemiBold;
    font-size: 24px;
    line-height: 32px; 
    text-transform: capitalize;
    margin-bottom: 15px;
}
.innovators-main-content p{
    color:  #575757;
    font-family: Light;
    font-size: 16px;
    line-height: 24px; 
}
.innovators-main-content,.innovators-main{
    margin: 4rem 0;
}
.innovators-main div:last-child img:first-child {
    margin-bottom: 15px;
}
.innovators-main {
    display: flex;
    gap: 15px;
}
.innovators-main > div{
    flex: 1;
}
.glance-slider,.slick-slider,.slick-list,.slick-track{
    transform: translate3d(0px, 0px, 0px);
    }
@media(min-width:992px) and (max-width:1199.90px){
    .glance-slider .common-slider .slick-prev {
        left: -1.5rem;
    }
    .glance-slider .common-slider .slick-next {
        right: -1.5rem;
    }
    .glance-content h3{
        font-size: 30px;
    }
    .glance-content p{
        font-size: 17px;
    }
}
@media(min-width:768px) and (max-width:991.90px){
    .glance-slider .common-slider .slick-prev {
        left: -1.5rem;
    }
    .glance-slider .common-slider .slick-next {
        right: -1.5rem;
    }
    .glance-content h3{
        font-size: 28px;
    }
    .glance-content p{
        font-size: 16px;
    }
    .glance-main,.glance-ul,.glance-slider {
        float: left;
        width: 100%;
        margin-top: 0;
    }
    .glance-content{
        align-items: center;
    }
    .glance-content-inner {
        margin-top: 22px;
    }
    .innovators-main-content, .innovators-main {
        margin: 0 0;
    }
    .innovators-main-content h3{
        font-size: 21px;
    }
    .innovators-main-content p{
        font-size: 15px;
        line-height: 23px;
    }
}
@media(min-width:481px) and (max-width:767.90px){
    .glance-slider .common-slider .slick-prev {
        left: -1.5rem;
    }
    .glance-slider .common-slider .slick-next {
        right: -1.5rem;
    }
    .glance-content h3{
        font-size: 24px;
    }
    .glance-content p{
        font-size: 16px;
    }
    .glance-main,.glance-ul,.glance-slider {
        float: left;
        width: 100%;
        margin-top: 0;
    }
    .glance-content{
        align-items: center;
    }
    .glance-content-inner {
        margin-top: 22px;
    }
    .innovators-main-content, .innovators-main {
        margin: 0 0;
    }
    .innovators-main-content h3{
        font-size: 20px;
    }
    .innovators-main-content p{
        font-size: 15px;
        line-height: 23px;
    }
    .glance-ul li{
        margin-left: 20px;
    }
    .glance-content > div{
        padding: 1rem;
    }
    .glance-content label{
        font-size: 22px;
    }
}
@media(min-width:481px) and (max-width:575.90px){
    .glance-ul li{
        padding: 6px 7px;
    }
    .glance-slider .common-slider .slick-prev {
        left: -1rem;
    }
    .glance-slider .common-slider .slick-next {
        right: -1rem;
    }
    .glance-content p{
        line-height: 24px;
    }
    
}
@media(min-width:320px) and (max-width:480.90px){
    .glance-slider .common-slider .slick-prev {
        left: -1rem;
    }
    .glance-slider .common-slider .slick-next {
        right: -1rem;
    }
    .glance-content p{
        line-height: 24px;
        font-size: 15px;
    }
    .glance-content h3{
        font-size: 22px;
    }
    .glance-main,.glance-ul,.glance-slider {
        float: left;
        width: 100%;
        margin-top: 0;
    }
    .glance-content{
        align-items: center;
        flex-direction: column;
    }
    .glance-content-inner {
        margin-top: 22px;
    }
    .innovators-main-content, .innovators-main {
        margin: 0 0;
    }
    .innovators-main-content h3{
        font-size: 18px;
    }
    .innovators-main-content p{
        font-size: 15px;
        line-height: 23px;
    }
    .glance-ul li{
        margin-left: 0px;
        padding: 5px;
        font-size: 13px;
    }
    .glance-content > div{
        padding: 1rem;
    }
    .glance-content label{
        font-size:18px;
    }
    
}