.MobileMenu{
 position: fixed;
  bottom: 0;
  left: 0;
  background: #fff;
  z-index: 111;
  box-shadow: 1px 1px 5px 1px #c7c7c7;
}
.MobileMenu ul{
    padding: 0; margin: 0; display: flex;
   }
.MobileMenu ul li{
    padding: 1.2rem 1rem .1rem; margin: 0; display: flex;
    list-style-type: none;
    color: #000;
    font-size: .75rem;
    background-repeat: no-repeat;
    background-position: top, center;
    /* text-transform: uppercase; */
   }
   .MobileMenu ul li:hover{
    background-color: #f7f7f7;
   }

.MobileMenu ul li:first-child{
    background-image: url("./../Images/about.png");
  
}
.MobileMenu ul li:nth-child(2){
    background-image: url("./../Images/services.png");
}
.MobileMenu ul li:nth-child(3){
    background-image: url("./../Images/products.png");
}
.MobileMenu ul li:nth-child(4){
    background-image: url("./../Images/contact.png");
}
.MobileMenu ul li span{
  width: 100%;
  position: fixed;
  z-index: 112;
  bottom: 38px;
  background: #f7f7f7;
  left: 0;
  flex-wrap: wrap;
  display: none;
  padding: .3rem 0;
}
.MobileMenu ul li:hover span{
    display: flex;
}
.MobileMenu ul li span h6{
    font-size: .8rem;
    padding: .3rem .5rem;
    margin: .2rem;
    background: #fff;
    border-radius: .2rem;
    color:#000;
   
}
.MobileMenu ul li span h6 a, .MobileMenu ul li a{
    color: #000;
    text-decoration: none;
}

@media(min-width:992px) and (max-width:5000px){
.MibileMenuHideSHow{
    display: none;
}
}

@media(min-width:100px) and (max-width:991.90px){
    .MibileMenuHideSHow{
        display: block;
    }
    }
