.aos-init[data-aos].aos-animate {
    transform: unset;
 }
.tech-main p{
    color: #0199DC;
    font-family: Medium;
    font-size: 18px;
    line-height: 22px;
}
.tech-main ul{
    display: flex;
    margin-bottom: 0;
    border-radius: 10px;
    background: #EFEFEF; 
    padding: 15px 27px 15px 13px; 
    gap: 40px; 
    list-style: none;
    width: fit-content;
}
.tech-main > div{
    margin-bottom: 45px;
}
.tech-main {
    margin-top: 50px;
}
.tech-main ul li{
    position: relative;
}
.tech-main li:hover .onhover-content{
    display: block;
}
.onhover-content {
    background: #fff;
    position: absolute;
    padding: 15px;
    width: 450px;
    z-index: 1;
    top: 60px;
    box-shadow: 0px 8px 34px 0px rgba(0, 0, 0, 0.09);
    display: none;
}
.tech-main ul li:last-child .onhover-content{
    right: 0;
}
.count-sc label{
    color: #4B4747;
    display: block;
    font-size: 18px;
    margin-bottom: 0;
}
.count-sc svg {
    font-size: 24px;
    margin-right: 7px;
}
.count-sc span{
    font-size: 25px;
    display: inline-block;
    vertical-align: middle;
    font-family: Medium;
    color: #4B4747;
}
.count-sc {
    width: 33%;
    display: inline-block;
    border-right: 1px solid #ddd;
    padding-left: 15px;
    vertical-align: top;
}
.count-sc:first-child{
    padding-left: 0;
    padding-right: 10px;
}
/* .count-sc:nth-last-child(2){
    border: none;
} */
.onhover-content p {
    color: #000;
    font-family: Light;
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 0;
}
@media(min-width:992px) and (max-width:1199.90px){
    .count-sc span{
        font-size: 23px;
    }
    .count-sc label{
        font-size: 17px;
    }
    .right-tech-main li:not(:first-child) .onhover-content {
        right: 0;
    }
    .tech-main ul {
        gap: 15px;
    }
}
@media(min-width:768px) and (max-width:991.90px){
    .count-sc span{
        font-size: 22px;
    }
    .count-sc label{
        font-size: 16px;
    }
    .tech-main li:not(:first-child) .onhover-content {
        left: 0;
    }
    .tech-main ul {
        gap: 15px;
    }
    .tech-main > div {
        margin-bottom: 25px;
    }
    .tech-main.right-tech-main{
        margin-top: 0;
    }
}
@media(min-width:481px) and (max-width:767.90px){
    .count-sc span{
        font-size: 20px;
    }
    .count-sc label{
        font-size: 16px;
    }
    .tech-main li:not(:first-child) .onhover-content,.tech-main ul li:last-child .onhover-content {
        right: 0;
        left: auto;
    }
    .tech-main li:first-child .onhover-content,.tech-main li:nth-child(2) .onhover-content,.tech-main li:nth-child(3) .onhover-content,.tech-main li:nth-child(4) .onhover-content {
        left: 0;
        right: auto;
    }
    .tech-main li:nth-child(5) .onhover-content{
        left: -5rem;
    }
    .tech-main li .onhover-content.native-cnt{
        right: auto !important;
        left: -5rem !important;
    }
    .tech-main li .onhover-content{
        width: 350px;
        padding: 7px;
    }
    .count-sc{
        width: 50%;
    }
    .count-sc:nth-child(3){
        padding-left: 0;
    }
    .tech-main ul {
        gap: 12px;
    }
    .tech-main > div {
        margin-bottom: 20px;
    }
    .tech-main.right-tech-main{
        margin-top: 0;
    }
    .count-sc svg
    {
        font-size: 20px;
    }
    .onhover-content p{
        font-size: 14px;
    }
}
@media (min-width: 481px) and (max-width: 575.9px) {
    .tech-main li .onhover-content {
      width: 250px;
    }
    .count-sc svg
    {
        font-size: 20px;
    }
    .onhover-content p{
        font-size: 14px;
    }
    .count-sc span{
        font-size: 20px;
    }
    .count-sc label{
        font-size: 16px;
    }
}
@media (min-width: 320px) and (max-width: 480.9px) {
    .tech-main li .onhover-content {
      width: 250px;
    }
    .tech-main ul{
        flex-wrap: wrap;
        width: 100%;
        gap: 0;
    }
    .tech-main ul li{
        flex: 50%;
        margin-bottom: 15px;
    }
    .tech-main ul li img{
        margin: 0 auto;
        text-align: center;
        display: block;
    }
    .tech-main li:nth-child(even) .onhover-content{
        right: 0;
    }
    .count-sc{
        width: 50%;
    }
    .count-sc svg
    {
        font-size: 18px;
    }
    .onhover-content p{
        font-size: 14px;
    }
    .count-sc span{
        font-size: 18px;
    }
    .count-sc label{
        font-size: 16px;
    }
    .count-sc:nth-child(3){
        padding-left: 0;
    }
    .tech-main {
        margin-top: 0;
    }
}