.product-list select{
    color: #1970CA;
    font-family: Light;
    font-size: 18px;
    line-height: 40px;
    border-radius: 8px;
    background: #F1F1F1;
    outline: none;
    border: none;
    padding: 10px 35px 10px 13px;
    width: 400px;
    -webkit-appearance :none;
    appearance: none;
}
.product-list {
    position: relative;
}
.product-list:after{
    content: "";
    position: absolute;
    right: 10px;
    background: url('data:image/svg+xml,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 320 512" height="25px" width="25px" xmlns="http://www.w3.org/2000/svg" style=" color: %23000000;"><path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"></path></svg>');
    width: 25px;
    height: 25px;
    top: 15px;
}
.breadcrum-list{
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
    margin-bottom: 0rem;
}
.container-blue{
    border-radius: 30px;
    background: linear-gradient(144deg, #F1FBFF 20.92%, #EAF8FF 65.53%);
    padding: 3rem !important;
    margin: 3rem 0;
}
.products-features h3{
    color: #0098DB;
    font-family: SemiBold;
    font-size: 24px;
    line-height: 42px; 
}
.products-features ul{
    padding-left: 20px;
}
.products-features li{
    color: #000;
    font-family: Light;
    font-size: 18px;
    line-height: 26px; 
    margin-bottom: 10px;
}
.allproduct-banner .banner-name a{
    font-size: 15px;
    color: #fff;
    text-decoration: none;
}
.DiscriptionText p{
    font-size: 1rem;
}

.DiscriptionText p::before {
    width: 0.5em;
    float: left;
    background: #08abfe;
    border-radius: 100%;
    margin-right: .5rem;
    content: "";
    position: inherit;
    height: .5rem;
    margin-top: .5rem;
}
.breadcrum-list .main-header h2{
    text-align: left;
}
@media(min-width:481px) and (max-width:767.90px){
    .breadcrum-list{
        flex-direction: column;
        gap: 0;
    }
    .product-list select {
        width: 100%;
    }
    .product-list {
        width: 65%;
    }
    .breadcrum-list .main-header h2 {
        text-align: center;
    }
    .product-list::after{
        top: 10px;
    }
    .product-list select{
        font-size: 17px;
        padding: 3px 35px 3px 13px;
    }
}
@media(min-width:320px) and (max-width:480.90px){
    .breadcrum-list{
        flex-direction: column;
        gap: 0;
    }
    .product-list select {
        width: 100%;
    }
    .product-list {
        width: 100%;
    }
    .product-list select{
        font-size: 16px;
        padding: 3px 35px 3px 13px;
    }
    .breadcrum-list .main-header h2 {
        text-align: center;
    }
    .product-list::after{
        top: 10px;
    }
}