.talk-sc{
    background:url(./../../Images/talk-bg.png) no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    padding:12rem 0 10rem;
}
.talk-sc h2{
    color: #FFF;
    text-transform: capitalize;
    font-family: HeadingFont;
    font-size: 54px;
    line-height: 72px;
}
.talk-sc a{
    color: #0098DB;
    font-size: 14px;
    line-height: 24px; 
    text-decoration: none;
    padding: 7px 20px; 
    border-radius: 8px;
    background: #FFF; 
    margin-top: 40px;
    display: block;
    width: fit-content;
    margin:40px auto 0;
    cursor: pointer;
}
.talk-sc a:hover{
    color: #0098DB;
    text-decoration: none;
}
@media(min-width:992px) and (max-width:1199.90px){
    .talk-sc h2 {
        font-size: 32px;
        line-height: 50px;
    }
}
@media(min-width:768px) and (max-width:991.90px){
    .talk-sc h2 {
        font-size: 30px;
        line-height: 45px;
    }
    .talk-sc{
        padding: 8rem 0 7rem;
    }
    .footer-links-bottom{
        flex-wrap: wrap;
    }
}
@media(min-width:481px) and (max-width:767.90px){
    .talk-sc h2 {
        font-size: 25px;
        line-height: 35px;
    }
    .talk-sc{
        padding: 6rem 0 5rem;
    }
    .footer-links-bottom{
        flex-wrap: wrap;
    }
}
@media(min-width:320px) and (max-width:480.90px){
    .talk-sc h2 {
        font-size: 20px;
        line-height: 25px;
    }
    .talk-sc{
        padding: 6rem 0 5rem;
    }
    .footer-links-bottom{
        flex-wrap: wrap;
    }
}