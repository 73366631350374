.job-application-sc .form-field {
    margin-bottom:20px;
    position: relative;
}
.job-application-sc .form-field input,
.job-application-sc .form-field select{
    width: 100%;
    border:none;
    outline: 0;
    border-bottom: 1px solid #DCDCDC;
    padding-bottom: 12px;
    color: #9B9B9B;
    font-size: 14px;
}
.job-application-sc .form-field.submit-field {
    text-align: center;
    margin-bottom: 0;
}
.job-application-sc .form-field.submit-field input{
    width: 100%;
    border: 0;
    outline: 0;
    padding-bottom: 12px;
    background: #1970CA;
    max-width: 170px;
    border-radius: 6px;
    color: #FFFFFF;
    margin-bottom: 0px;
}

.job-application-sc input[type="submit"] {
    display: block;
    width: -moz-fit-content;
    width: fit-content;
    color: #fff;
    font-family: SemiBold;
    font-size: 18px;
    border: 1px solid #1970ca;
  }
.job-application-sc .form-field input::placeholder{
    color: #9B9B9B;
}

.job-application-sc {
    margin: 4rem 0rem;
}
.job-application-container {
    padding: 4rem 0rem;
    display: flex;
    align-items: end;
    justify-content: space-between;
    gap: 10px;
}

.backdrop {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    z-index: -1;
    height: 100%;
    width: 55%;
}
.backdrop img{
    height: 100%;
    width: 100%;
}
.job-application-container .content{
    flex: 1;
    padding-left: 45px;
    padding-bottom: 80px;
}
.job-application-container .content p{
    margin: 0;
    font-size: 54px;
    color: #0098DB;
    line-height: 66px;
    font-family: Bold;
}
.job-application-form {
    flex: 1;
    padding: 35px;
    border-radius: 16px;
    border-top: 12px solid #1970CA;
    background: #FFF;
    box-shadow: 0px 3px 13px 0px rgba(67, 67, 67, 0.11);
}
.job-application-form .select-field {
    position: relative;
}
.job-application-form .select-field::after {
    content: "";
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
    background-image: url('data:image/svg+xml,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 320 512" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg" style=" color: %239b9b9b;"><path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"></path></svg>');
    background-size: 100% 100%;
    height: 20px;
    width: 20px;
}
.job-application-form .select-field select{
    appearance: none;
    background: #fff;
}
.job-application-form .uplode-field {
    margin-bottom: 50px;
}

.job-application-form small{
    color: red;
    position: absolute;
    bottom: -16px;
    left:0;
    font-size: 11px;
    text-transform: capitalize;
  }

  .job-application-sc .form-field.uplode-field input{
    padding: 0;
  }

  .uplode-field label{
    flex-direction: column;
    min-width: unset;
    max-width: unset;
    height: unset;
    padding: 15px;
    border-radius: 10px;
    border: 1px dashed #1970CA;
    padding-top: 65px;
    padding-bottom: 30px;
    position: relative;
  }
  .uplode-field label::before{
    content: "";
    background-image: url(./../../Images/uplode-img.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 45px;
    width: 45px;
    position: absolute;
    left: 50%;
    top: 15px;
    transform: translate(-50%, 0%);
  }
  .uplode-field svg{
    display: none;
  }
  .uplode-field div {
    flex-direction: column;
    align-items: center;
  }

  .uplode-field div span {
    color: #1970CA;
    font-size: 15px;
    text-decoration: none;
    text-overflow: unset;
    max-width: unset;
  }

  .file-name {
    position: absolute;
    left: 50%;
    bottom: 10px;
    transform: translate(-50%,0%);
    color: #ff0000;
    font-size: 11px;
    font-family: SemiBold;
  }
  .file-types{
    max-width: 100% !important;
  }


  @media (max-width:1199px){
    .job-application-container .content p {
        font-size: 54px;
        line-height: 60px;
    }
    .job-application-container .content p br{
        display: none;
    }
    .job-application-container .content {
        padding-left: 40px;
        padding-bottom: 50px;
    }
  } 
  
  @media (max-width:991px){
    .job-application-container {
        padding: 2rem 0rem;
        flex-direction: column;
    }
    .job-application-container .content {
        padding-left: 0;
        padding-bottom: 0;
    }
    .job-application-container .content p {
        font-size: 32px;
        line-height: 35px;
    }
    
    .job-application-form {
        padding: 25px;
        width: 70%;
        border-top: 8px solid #1970CA;
    }
    .job-application-form .uplode-field {
        margin-bottom: 30px;
    }
  }
  
  @media (max-width:767px){
    .career-form-banner-sc {
        height: 100%;
    }
    .job-application-container {
        padding: 2rem 0rem;
        align-items: center;
    }
    .job-application-container .content p {
        font-size: 22px;
        line-height: 26px;
    }
    .job-application-form {
        width: 100%;
    }
    .job-application-sc {
        margin: 2rem 0rem;
    }
  }
  
  @media (max-width:575px){
    .job-application-sc .form-field {
        margin-bottom: 15px;
    }
    .job-application-form {
        padding: 20px;
    }
  }
  
  @media (max-width:480px){
      
  }
  
  @media (max-width:360px){
      
  }