.connect-img-box {
    position: relative;
    background: url(./../../Images/connect-img.png);
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 7rem;
    padding-right: 2rem;
}
.connect-img-box p {
    color:  #fff;
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-family: Light;
    margin-top: 1.5rem;
}
.form-field.submit-form-field {
    margin: 0;
}
.connect-sc{
    padding:0 0 3rem 0;
}
.connect-sc h2{
    text-align: left;
    color: #fff;
}
.submit-form div{
    margin-bottom: 20px;
    position: relative;
    flex-wrap: wrap;
    font-size: 18px;
}

.intersert-div{
    position: relative;
}
.submit-form input,.submit-form select,.submit-form .checkbox-input-toggle{
    width: 100%;
    color: #9B9B9B;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    border:none;
    border-bottom: 1px solid #DCDCDC;
    outline: none;
    padding-bottom: 12px;
    background-color: #fff;
}
.submit-form input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #9B9B9B;                             
}
.submit-form input::-moz-placeholder { /* Firefox 19+ */
    color: #9B9B9B;
    opacity:1;
}
.submit-form input:-ms-input-placeholder { /* IE 10+ */
    color: #9B9B9B;
}
.submit-form input:-moz-placeholder { /* Firefox 18- */
    color: #9B9B9B;
    opacity:1;
}
  
.submit-form-field input[type="submit"] {
    display: block;
    width: fit-content;
    color: #fff;
    font-family: SemiBold;
    font-size: 18px;
}
.submit-form small {
    color: red;
    position: absolute;
    bottom: -16px;
    left:0;
    font-size: 11px;
    text-transform: capitalize;
}
.relative-sc .submitted-data{
    color: red;
    position: absolute;
    right: 0;
    top: 10px;
    text-transform: capitalize;
    font-size: 16px;
}
.relative-sc{
    position: relative;
}
.form-field.radio-field input:nth-child(2){
    margin-left: 30px;
}
.form-field.radio-field input {
    display: inline-block;
    width: 28px;
    margin-right: 5px;
    height: 28px;
}
.form-field.radio-field {
    display: flex;
}
.form-field.radio-field div{
    font-size: 18px;
    margin-right: 25px;
}
.submit-form .show-hide-check{
    position: absolute;
    top: 34px;
    left: 0;
    border: 1px solid #ddd;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding:10px;
    background: #fff;
    width: 100%;
    z-index: 1;
    height: 200px;
    overflow-y: auto;
}

.form-field.captch-field span, .form-field.captch-field input{
    flex:0;
    margin-right: 7px;
}
.form-field.captch-field span:first-child{
    color: #fff;
    text-decoration: line-through;
    background-color: #000;
    padding: 3px 10px;
    letter-spacing: 5px;
    font-style: italic;
    width: 120px;
    display: inline-block;
    text-align: center;
}
.form-field.captch-field span{
    width: 20px;
    display: inline-block;
}
.form-field.captch-field  input{
    width: 71%;
    display: inline-block;
    margin: 0;
}
.submit-form {
    margin-top: 1rem;
    padding-left: 2rem;
}
.intersert-div input {
    width: auto;
}
.submit-form .show-hide-check > div{
    margin-bottom: 3px;
}
.show-hide-check label {
    font-size: 1rem;
    line-height: 1.5rem;
    font-family: SemiBold;
    margin-top: 0;
    color: #000;
}
.show-hide-check span {
    font-size: 1rem;
    color: #4B4747;
    font-family: Light;
    padding-left: 5px;
}
@media(min-width:768px) and (max-width:991.90px){
    .submit-form .submitted-data{
        font-size: 14px;
    }
    .connect-img-box{
        height: auto;
        padding: 3rem 2rem 3rem 5rem;
    }
    .connect-sc h2{
        font-size: 35px !important;
        line-height: 38px;
    }
    .connect-img-box p{
        font-size: 1rem;
    }
}
@media(min-width:481px) and (max-width:767.90px){
    .submit-form .submitted-data{
        font-size: 13px;
    }
    .connect-img-box{
        padding: 4rem 2rem 4rem 7rem;
    }
    .connect-img-box p{
        font-size: 1rem;
    }
    .submit-form{
        padding-left: 0;
    }
    .submit-form input[type="submit"]{
        font-size: 16px;
    }
    .form-field.radio-field input{
        width: 22px;
        height: 22px;
    }
}
@media(min-width:320px) and (max-width:480.90px){
    .submit-form .submitted-data{
        font-size: 13px;
    }
    .connect-img-box{
        padding: 2rem 2rem 2rem 5rem;
    }
    .connect-img-box p{
        font-size: 1rem;
    }
    .submit-form{
        padding-left: 0;
    }
    .submit-form input[type="submit"]{
        font-size: 16px;
    }
    .form-field.radio-field input{
        width: 22px;
        height: 22px;
    }
}