.IndiaHeading{
    width: 17%;
  float: left;
  padding: 0;
  margin: 0;
  font-size: 2rem;
  font-weight: bold;
  color: #0098DB;
  position: relative;
}
.IndiaHeading::after{
    content: " ";
    border: .01rem solid #0098DB;
    width: 15%;
    position: absolute;
    left: 0;
    bottom: 3px;
    height: 1px;
}
.AddresTable{
    width: 83%;
  float: left;
  padding: 0;
  margin: 0;
}
.AddresTable ul{
  width: 100%;
  float: left;
  padding: 0;
  margin: 0;
}

.AddresTable ul li {
  width: 29%;
  display: inline-grid;
  padding: 0 0 1.5rem 0;
  margin: 0 2%;
  list-style-type: none;
}
.AddresTable ul li:nth-child(2){
width: 50%;
}
.AddresTable ul li h2{
    font-size: 1.5rem;
  font-weight: bold;
  padding: 1rem 0 .4rem;
  margin: 0;
  position: relative;
}
.AddresTable ul li h2::after{
    content: " ";
    border: .01rem solid #0098DB;
    width: 8%;
    position: absolute;
    left: 0;
    bottom: 3px;
    height: 1px;

}
.AddresTable ul li h6 {   
    float: left;
    padding: .3rem 0;
    margin: 0;
    font-size: 1rem;     
  line-height: 1.5rem;
  color: #757575;
  }
  .AddresTable ul li h6 a{
    color: #757575;
  }
.AddresTable ul li span{
    font-size: 1rem;
    color: #000;
    font-family: Medium;
}

.AddresTable ul li:first-child h6:nth-child(4) span, .AddresTable ul li:first-child h6:nth-child(3) span{
  display: none;
}

@media(min-width:480px) and (max-width:767.90px){
     .IndiaHeading {width: 100%  }
    .AddresTable {width: 100% }
    .AddresTable ul li h2 {
        font-size: 1.3rem;}
}

@media(min-width:320px) and (max-width:479.90px){
    .IndiaHeading {width: 100%  }
   .AddresTable {width: 100% }
   .AddresTable ul li h2 {
       font-size: 1rem;}
    .AddresTable ul li {
        width: 46%;}
}

@media(min-width:120px) and (max-width:767.90px){
  .AddresTable ul li {
    width: 100%;}
  .AddresTable ul li:nth-child(2) {
      width: 100%;
    }
}