.footer-sc{
    border-top: 1px solid #EAEAEA; 
    padding: 27px 0 0;
}
.footer-links-box h4{
    color: #2998D3;
    font-size: 16px;
    font-family: SemiBold;
    margin-bottom: 15px;
}
.footer-links-box ul{
    padding-left: 0;
    list-style: none;
}
.footer-links-box li a{
    color: #6D6D6D;
    font-size: 14px;
    display: inline-block;
    margin-bottom: 7px;
    text-decoration: none;
}
.footer-links-box ul{
    float: left;
}
.footer-links-box,.company-info-box{
    margin-top: 40px;
}
.info-inner p,.info-inner label,.info-inner a{
    color: #6D6D6D;
    font-size: 14px;
    line-height: 24px;
    text-decoration: none;
    margin-bottom: 0;
}
.info-inner{
    margin-bottom: 4px;
}
.company-info-box p{
    color: #6D6D6D;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 7px;
}
.company-info-box h3{
    color: #D52C27;
    font-size: 16px;
    font-family: Medium;
    line-height: 26px;
}
.footer-links {
    display: flex;
    gap: 25px;
}
.company-info-box {
    flex: 1.8;
}
.footer-links-box.middle-footer-box {
    flex: 1.7;
}
.footer-links-box {
    flex: 1;
}
.footer-links-box.middle-footer-box ul:last-child{
    padding-left: 3rem;
}
.social-icons {
    margin-top: 14px;
}
.social-icons a{
    margin-right: 24px;
}
.footer-links-bottom{
    display: flex;
    border-top: 1px solid #E8E8E8;
    padding: 20px 0;
    justify-content: space-between;
    margin-top: 12px;
}
.footer-links-bottom ul{
    padding-left: 0;
    list-style: none;
    display: flex;
    margin-bottom: 0;
}
.footer-links-bottom ul li a{
    color: #6D6D6D;
    font-size: 14px;
    position: relative;
    border-right: 1px solid #6D6D6D;
    padding: 0 20px;
    text-decoration: none;
}
.footer-links li a{
    transition: all 0.5s ease-in-out;
}
.footer-links li a:hover{
    color: #135BA5;
}
.footer-links-bottom ul li:last-child a{
    border: none;
}
.footer-links-bottom ul li:first-child a{
    padding-left: 0;
}
.footer-links-bottom p{
    color: #6D6D6D;
    font-size: 14px;
    margin-bottom: 0;
}
.info-inner-Link a {
    display: none;
}
.info-inner-Link h6 {
    display: inline-block;
    font-size: 14px;
}
@media(min-width:768px) and (max-width:991.90px){
    .footer-links-box.middle-footer-box ul:last-child {
        padding-left: 0;
    }
    .footer-links-box ul:nth-last-child(2) {
        margin-bottom: 0;
    }
}

@media(min-width:481px) and (max-width:767.90px){
    .company-info-box {
        flex: 1 100%;
    }
    .footer-links-box.middle-footer-box {
        flex: 100%;
    }
    .footer-links{
        flex-wrap: wrap;
    }
    .company-info-box p{
        margin-bottom: 5px;
        font-size: 15px;
    }
    .social-icons {
        margin-top: 0;
        display: flex;
    }
    .footer-links-box.middle-footer-box ul:last-child {
        padding-left: 0;
    }
    .footer-links-box ul{
        width: 50%;
        margin-bottom: 0;
    }
    .footer-links-box.service-link-box ul {
        width: 100%;
    }
    .footer-links-box.service-link-box li {
        width: 50%;
        float: left;
    }
    .footer-links-box{
        margin-top: 0;
    }
    .footer-links-bottom{
        flex-wrap: wrap;
    }
    .footer-links-bottom ul{
        margin-bottom: 20px;
    }
}
@media(min-width:320px) and (max-width:480.90px){
    .company-info-box {
        flex: 1 100%;
    }
    .footer-links-box.middle-footer-box {
        flex: 5;
    }
    .footer-links{
        flex-wrap: wrap;
    }
    .company-info-box p{
        margin-bottom: 5px;
        font-size: 14px;
    }
    .social-icons {
        margin-top: 0;
        display: flex;
    }
    .footer-links-box.middle-footer-box ul:last-child {
        padding-left: 0;
    }
    .footer-links-box ul{
        width: 100%;
        margin-bottom: 0;
    }
    .footer-links-box.service-link-box ul {
        width: 100%;
    }
    .footer-links-box.service-link-box li {
        width: 100%;
        float: left;
    }
    .footer-links-box{
        margin-top: 0;
        flex: 5;
    }
    .footer-links-bottom{
        flex-wrap: wrap;
    }
    .footer-links-bottom ul{
        margin-bottom: 20px;
        flex-wrap: wrap;
    }
    .footer-links-box li a{
        font-size: 13px;
        margin-bottom: 5px;
    }
    .footer-links-bottom ul li a{
        font-size: 13px;
        padding: 0 10px;
    }
    .footer-links-bottom p{
        font-size: 13px;
    }
}
@media(min-width:320px) and (max-width:359.90px){
    .footer-links-bottom ul li a {
        font-size: 12px;
        padding: 0 7px;
    }
}

.HideShowFooterDiv{
display: none;
height: 2rem;
}

@media(min-width:100px) and (max-width:767.90px){
    .HideShowFooterDiv{
        display: block;
        }
        .info-inner-Link a {
            display: inline-block;
        }
        .info-inner-Link h6 {
            display: none;
        }
    }