.hiring-content p{
    color: #6D6D6D;
    font-family: Light;
    font-size: 1.2rem;
    line-height: 27px; 
}
.process-main {
    display: flex;
    gap: 3rem;
    margin: 5rem 0;
}
.hiring-content {
    flex-basis: 27%;
}
.process-flow {
    flex-basis: calc(100% - 27%);
    display: flex;
    gap: 30px;
}
.process-flow > div {
    flex: 1;
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 9px 40px 0px rgba(0, 0, 0, 0.11);
    padding: 20px;
    position: relative;
}
.process-flow span {
    color: #FFF;
    font-family: SemiBold;
    font-size: 24px;
    line-height: 60px;
    width: 63px;
    height: 63px;
    background: #8DE6EB;
    display: inline-block;
    text-align: center;
    border-radius: 100%;
    position: absolute;
    top: -30px;
    left: 20px;
}
.process-flow > div:nth-child(2) span{
    background: #1B3662;
}
.process-flow > div:nth-child(3) span{
    background:#00B027;
}
.process-flow label{
    color: #000;
    font-family: SemiBold;
    font-size: 24px;
    line-height: 32px;
    margin-top: 2rem;
}
.process-flow ul{
    margin-bottom: 0;
    padding-left: 20px;
}
.process-flow li{
    color: #6D6D6D;
    font-family: Light;
    font-size: 18px;
    line-height: 32px;
    list-style: disc;
}
.process-flow div:nth-child(2) li:last-child, .process-flow div:nth-child(2) li:nth-child(4),
.process-flow div:nth-child(3) li:last-child, .process-flow div:nth-child(3) li:nth-child(4) {list-style-type: none;}

@media(min-width:992px) and (max-width:1199.90px){
    .hiring-content p{
        font-size: 17px;
        line-height: 25px;
    }
    .process-flow label{
        font-size: 22px;
        line-height: 28px;
    }
    .process-flow li{
        font-size: 16px;
        line-height: normal;
        margin-bottom: 7px;
    }
}
@media(min-width:768px) and (max-width:991.90px){
    .process-main{
        flex-direction: column;
        margin-top: 0;
    }
    .hiring-content p{
        font-size: 16px;
        line-height: 24px;
    }
    .process-flow label{
        font-size: 21px;
        line-height: 27px;
    }
    .process-flow li{
        font-size: 16px;
        line-height: normal;
        margin-bottom: 7px;
    }
}
@media(min-width:481px) and (max-width:767.90px){
    .process-main{
        flex-direction: column;
        margin-top: 0;
    }
    .hiring-content p{
        font-size: 15px;
        line-height: 24px;
    }
    .process-flow label{
        font-size: 20px;
        line-height: 27px;
    }
    .process-flow li{
        font-size: 15px;
        line-height: normal;
        margin-bottom: 7px;
    }
    .process-flow ul{
        padding-left: 12px;
    }
    .process-flow{
        gap: 10px;
    }
    .process-flow > div{
        padding: 12px;
    }
}
@media(min-width:320px) and (max-width:480.90px){
    .process-main{
        flex-direction: column;
        margin-top: 0;
        margin-bottom: 0;
    }
    .hiring-content p{
        font-size: 14px;
        line-height: 22px;
    }
    .process-flow label{
        font-size: 18px;
        line-height: 24px;
    }
    .process-flow li{
        font-size: 14px;
        line-height: normal;
        margin-bottom: 7px;
    }
    .process-flow ul{
        padding-left: 12px;
    }
    .process-flow{
        gap: 10px;
        flex-direction: column;
    }
    .process-flow > div{
        padding: 12px;
        margin-bottom: 2rem;
    }
    .journey-main .common-blue-btn {
        padding: 9px 15px;
    }
}