.careers-sc .accordion-button{
    color: #0199DC;
    font-family: SemiBold;
    font-size: 1.125rem;
    padding: 0;
    background: none;
    border: none;
    width: 100%;
    text-align: left;
    position: relative;
}
.careers-sc .accordion-button:after{
    content: "";
    background-image: url('data:image/svg+xml,<svg stroke="rgb(1, 153, 220)" fill="rgb(1, 153, 220)" stroke-width="0" viewBox="0 0 1024 1024" height="25px" width="25px" xmlns="http://www.w3.org/2000/svg"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm192 472c0 4.4-3.6 8-8 8H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h368c4.4 0 8 3.6 8 8v48z"></path></svg>');
    fill: #0199DC;
    width: 25px;
    height:25px;
    display: inline-block;
    position: absolute;
    right: 0;
    top: .5rem;
}
.careers-sc .accordion-button.collapsed:after{
    background-image: url('data:image/svg+xml,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="25px" width="25px" xmlns="http://www.w3.org/2000/svg"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm192 472c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z"></path></svg>');
}
.careers-sc .accordion-button.collapsed{
    color: #000; 
}
.careers-sc .accordion-body{
    color: #575757;
    font-family: Light;
    font-size: 1rem;
    line-height: 1.5rem; 
    padding-bottom: 1.5rem;
}
.careers-sc .accordion-item {
    border-bottom: 1px solid #E3E3E3;
}
.careers-sc .accordion-header{
    line-height: normal;
    font-size: revert;
    padding: 1.25rem 0;
    margin: 0;
}
.careers-sc{
    margin-bottom: 5rem;
}
.CareerTable h3{
    width: 50%;
  display: inline-block;   
}
.CareerTable span{
    width: 45%;
   display: inline-block;
   text-align: right;
}

.CareersDataTable{
    width: 100%;
    display: inline-block;
    }
.CareersDataTable p{
    width: 100%;
    display: inline-block;
        }
.CareersDataTable p span{
    width: 100%;
    display: block;
        }
.CareersDataTable p b{
    width: 100%;
    display: block;
    font-size: 1.2rem;
    font-family: SemiBold;
            }

.CareersDataTable a,.CareersDataTable a:hover{
    background: red;
    border: 0;
    padding: .3rem 1rem;
    color: #fff;
    border-radius: .3rem;
    box-shadow: 0 0 10px #6d6d6d;
            }

@media(min-width:992px) and (max-width:1199.90px){
    .careers-sc .accordion-body{
        font-size: 15px;
    }
}
@media(min-width:481px) and (max-width:767.90px){
    .careers-sc .accordion-body{
        font-size: 15px;
    }
    .careers-sc .accordion-button{
        font-size: 1.05rem;
        padding-right:7px;
    }
    .careers-sc .accordion-button::after{
        top: 0;
    }
    .careers-sc {
        margin-bottom: 2rem;
    }
}
@media(min-width:320px) and (max-width:480.90px){
    .careers-sc .accordion-body{
        font-size: 14px;
    }
    .careers-sc .accordion-button{
        font-size: 1rem;
        padding-right:25px;
    }
    .careers-sc .accordion-button::after{
        top: 0;
    }
    .careers-sc {
        margin-bottom: 1.8rem;
    }
    .CareerTable h3{
        font-size: 1.1rem;
    }
}