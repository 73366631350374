.main-header h2{
    text-align: center;
    color: #000;
    font-family:HeadingFont;
    font-size: 54px;
    line-height: 50px;
    margin-bottom: 2.75rem;
}
.main-header.no-space-head h2{
    margin-bottom: 1rem;
}
.common-blue-btn,.submit-form .common-blue-btn,.job-application-sc .common-blue-btn{
    border-radius: 30px;
    border:1px solid #1970CA; 
    padding: 9px 29px 9px 29px; 
    color: #FFF;
    text-align: center;
    font-family: Light;
    font-size: 16px;
    background: linear-gradient(to top, #fff 50%, #1970CA 50%);
    background-size: 100% 200%;
    background-position: top left;
    transition: all .3s ease-out;
}
.common-blue-btn:hover,.submit-form input[type="submit"]:hover,.inner-header-links li .inner-header-content a:hover,.job-application-sc input[type="submit"]:hover {
    color: #1970CA;
    text-decoration: none;
  background-position: bottom left;
  border: 1px solid #1970CA;

}
.common-blue-btn.white-common-btn{
    background: linear-gradient(to top, #1970CA 50%, #fff 50%);
    background-size: 100% 200%;
    background-position: top left;
    transition: all .3s ease-out;
}
.common-blue-btn.white-common-btn:hover{
    color: #fff;
    text-decoration: none;
  background-position: bottom left;
}

.main-header p{
    color: #4B4747;
    text-align: center;
    font-family: Light;
    font-size: 1.125rem;
    line-height: 1.625rem;
    
}
.main-header.white-head h2,.main-header.white-head p{
    color: #fff;
}
@media(min-width:992px) and (max-width:1199.90px){
    .main-header h2,.industry-box .main-header h2{
        font-size: 45px !important;
        margin-bottom: 1.8rem;
    }
    .main-header p{
        font-size: 1.1rem;
    }
}
@media(max-width:991px){
    [data-aos^="fade"][data-aos^="fade"]{
        opacity:1 !important;
    }
    [data-aos^="fade"][data-aos^="fade"]{
        transform: translate3d(0,0px,0) !important;
    }
    [data-aos="flip-left"] {
        transform: perspective(0px) rotateY(0deg) !important;
    }
    [data-aos^="flip"][data-aos^="flip"] {
        backface-visibility: visible !important;
        transition-property: none !important;
    }
    [data-aos^="zoom"][data-aos^="zoom"]{
        opacity: 1 !important;
        transition-property: none !important;
    }
    [data-aos="zoom-in"] {
        transform: inherit !important;
    }
}
@media(min-width:768px) and (max-width:991.90px){
    .main-header h2,.industry-box .main-header h2{
        font-size: 42px !important;
        margin-bottom: 1rem;
    }
    .main-header p{
        font-size: 1rem;
    }
    .main-header p br{
        display: none;
    }
}
@media(min-width:481px) and (max-width:767.90px){
    .main-header h2 br, .main-header p br{
        display: none;
    }
    .main-header h2,.industry-box .main-header h2{
        font-size: 40px !important;
        margin-bottom: 1rem;
    }
    .main-header p{
        font-size: 0.95rem;
    }
}
@media(min-width:320px) and (max-width:480.90px){
    .main-header h2 br, .main-header p br{
        display: none;
    }
    .main-header h2,.industry-box .main-header h2{
        font-size: 30px !important;
        margin-bottom: 1rem;
        line-height: 33px;
    }
    .main-header p{
        font-size: 0.9rem;
    }
}

.slick-prev::before, .slick-next::before{
    color: #1970CA !important;
}