.solution-sc{
    margin: 5rem 0;
}
.solution-sc .css-1ujnqem-MuiTabs-root{
    border-bottom: 1px solid #F2F2F2;
    margin-top: 60px;
}
.solution-sc .MuiButtonBase-root{
    color: #515151;
    font-size: 18px;
    line-height: normal; 
    letter-spacing: normal;
    padding:10px 20px;
    min-width: auto;
    max-width: fit-content;
}
.solution-sc .Mui-selected{
    color: #1970CA; 
    font-family: SemiBold;
}
.solution-sc .MuiTabs-indicator {
    background-color: #1970CA ;
}
.solution-sc .MuiSvgIcon-root{
    color: #1970CA;
    width: 29px;
    height: 29px;
    border: 1px solid #1970CA;
    border-radius: 100%;
}
.services-content h5{
    color: #000;
    font-family: Medium;
    font-size: 32px;
    line-height: normal; 
}
.services-content p{
    color: #4B4747;
    font-family: Light;
    font-size: 16px;
    line-height: 24px; 
    margin-bottom: 25px;
}
.services-main > div {
    flex: 1;
}
.services-main {
    display: flex;
    gap: 35px;
}
.solution-sc .MuiTabPanel-root{
    margin-top: 50px;
}
.solution-sc .Mui-disabled{
    display: none;
}

@media(min-width:992px) and (max-width:1199.90px){
    .solution-sc {
        margin: 4rem 0;
    }
    .solution-sc .MuiButtonBase-root{
        font-size: 17px;
    }
    .solution-sc .MuiSvgIcon-root{
        width: 27px;
        height: 27px;
    }
    .services-content h5{
        font-size: 30px;
    }
    .services-content p{
        font-size: 15px;
        line-height: 22px;
    }
}
@media(min-width:768px) and (max-width:991.90px){
    .solution-sc {
        margin: 4rem 0;
    }
    .solution-sc .MuiButtonBase-root{
        font-size: 16px;
    }
    .solution-sc .MuiSvgIcon-root{
        width: 27px;
        height: 27px;
    }
    .services-content h5{
        font-size: 28px;
    }
    .services-content p{
        font-size: 14px;
        line-height: 22px;
        text-align: justify;
    }
}
@media(min-width:481px) and (max-width:767.90px){
    .solution-sc .MuiButtonBase-root{
        display: block !important;
        font-size: 16px;
        padding: 10px 15px;
    }
    .solution-sc .Mui-disabled{
        display: none !important;
    }
    .MuiSvgIcon-root[data-testid="KeyboardArrowRightIcon"] {
        position: relative;
        right: 0.8rem;
    }
    .MuiSvgIcon-root[data-testid="KeyboardArrowLeftIcon"]{
        position: relative;
        left: -0.8rem;
    }
    .solution-sc .MuiTabPanel-root {
        margin-top: 10px;
    }
    .services-main{
        flex-direction: column;
    }
}
@media(min-width:320px) and (max-width:480.90px){
    .solution-sc .MuiButtonBase-root{
        display: block !important;
        font-size: 14px;
        padding: 7px 10px;
    }
    .solution-sc .Mui-disabled{
        display: none !important;
    }
    .MuiSvgIcon-root[data-testid="KeyboardArrowRightIcon"] {
        position: relative;
        right: 0rem;
    }
    .MuiSvgIcon-root[data-testid="KeyboardArrowLeftIcon"]{
        position: relative;
        left: 0rem;
    }
    .solution-sc .MuiTabPanel-root {
        margin-top: 10px;
        padding: 0;
    }
    .services-main{
        flex-direction: column;
    }
    .solution-sc {
        margin: 2rem 0;
    }
    .solution-sc .css-1ujnqem-MuiTabs-root{
        margin-top: 0;
    }
}