.client-sc{
    background:url(./../../Images/client-bg.png) no-repeat;
    width: 100%;
    height: 100%;
    padding: 7rem 0;
    background-size: 100% 100%;
    margin: 5rem 0;
}
.client-main-box{
    /* display: flex;
    gap: 50px; 
    justify-content: center;*/
    margin-top: 50px;
}
.client-main-box .slick-slide{
    padding: 0 25px;
}
.client-main-box .slick-slide img{
    margin: 0 auto;
    filter: grayscale(100%);
    opacity: .8;
}

.client-main-box .slick-slide img:hover{
    filter: grayscale(0%);
    opacity: 1;
}
.client-inner {
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.3);
    text-align: center;
    flex-basis: 24%;
    padding: 14px 20px;
  }
.client-main-box h4{
    color: #0B0B0B;
    font-family: Medium;
    font-size: 24px;
    line-height: 34px;
    margin-top: 30px; 
}
.client-main-box p{
    color:  #575757;
    font-family: Light;
    font-size: 16px;
    line-height: 24px;
}
@media(min-width:992px) and (max-width:1199.90px){
    .client-main-box h4{
        font-size: 22px;
        margin-top: 0;
    }
    .client-sc .slick-dots li button::before{
        color: #FFF !important;
    }
    .client-sc .slick-dots{
        bottom: -4rem;
    }
}
@media(min-width:481px) and (max-width:767.90px){
    .client-sc{
        margin: 2rem 0;
        padding: 4rem 0;
    }
    .client-sc .slick-dots{
        bottom: -2rem;
    }
    .client-sc  .slick-next{
        right: 0;
    }
    .client-sc .slick-prev{
        left: 0;
    }
}
@media(min-width:320px) and (max-width:480.90px){
    .client-sc{
        margin: 0rem 0;
        padding: 4rem 0;
    }
    .client-sc .slick-dots{
        bottom: -2rem;
    }
    .client-sc  .slick-next{
        right: 0;
    }
    .client-sc .slick-prev{
        left: 0;
    }
}