.services-main-overview{
    margin: 3rem 0;
}
.overview-box-inner h3{
    color: #000;
    font-family: SemiBold;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 0;
}
.overview-box-inner p{
    color:  #575757;
    font-family: Light;
    font-size: 18px;
    line-height: 25px;
    margin-top: 13px;
    margin-bottom: 25px;
}
.overview-box-main {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
}
.overview-box-main > div {
    flex-basis: 47%;
}
.overview-box-main > div:first-child{
    /* flex-basis: 100%; */
}
.overview-box-main > div:nth-child(odd){
    margin-top: 5rem;
}
.overview-box-inner a {
    display: block;
    width: fit-content;
    margin-top: 3rem;
}
@media(min-width:992px) and (max-width:1199.90px){
    .overview-box-inner h3{
        font-size: 30px;
    }
    .overview-box-inner p{
        font-size: 17px;
    }
}
@media(min-width:768px) and (max-width:991.90px){
    .overview-box-inner h3{
        font-size:28px;
    }
    .overview-box-inner p{
        font-size: 16px;
    }
    .overview-box-main{
        gap:40px;
    }
}
@media(min-width:481px) and (max-width:767.90px){
    .overview-box-inner h3{
        font-size:25px;
    }
    .overview-box-inner p{
        font-size: 16px;
    }
    .overview-box-main{
        gap:0px;
        flex-direction: column;
    }
    .overview-box-main > div,.overview-box-main > div:nth-child(odd) {
        margin-top: 2rem;
    }
    .overview-box-inner img {
        width: 70%;
        margin: 0 auto;
        text-align: center;
        display: block;
    }
    .overview-box-inner a{
        margin: 3rem auto 0;
    }
}
@media(min-width:320px) and (max-width:480.90px){
    .overview-box-inner h3{
        font-size:22px;
    }
    .overview-box-inner p{
        font-size: 15px;
    }
    .overview-box-main{
        gap:0px;
        flex-direction: column;
    }
    .overview-box-main > div,.overview-box-main > div:nth-child(odd) {
        margin-top: 2rem;
    }
    .overview-box-inner img {
        width: 100%;
        margin: 0 auto;
        text-align: center;
        display: block;
    }
    .overview-box-inner a{
        margin: 2rem auto 0;
    }
}