.employeeperk-descp p{
    color: #575757;
    font-family: Light;
    font-size: 24px;
    line-height: 32px; 
}
.employeeperk-sc{
    margin: 4rem 0;
}
.employeeperk-box-main {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
}
.employeeperk-box-main > div{
    flex-basis: 31%;
    padding: 25px;
    text-align: center;
    background: #EFEFEF; 
}
.employeeperk-box-main label{
    color: #000;
    font-family: SemiBold;
    font-size: 18px;
    line-height: 24px;
    display: block;
    margin-top: 20px;
}
.employeeperk-box-main > div.join-us-sc{
    background: #1970CA;
}
.employeeperk-box-main > div.join-us-sc label{
    color: #fff;
}
.join-us-sc a {
    cursor: pointer;
    display: block;
    text-decoration: none !important;
}
.join-us-sc a label{
    cursor: pointer;
    text-decoration: none;
}
@media(min-width:992px) and (max-width:1199.90px){
    .employeeperk-descp p{
        font-size: 21px;
        line-height: 30px;
    }
    .employeeperk-box-main{
        gap: 20px;
    }
}
@media(min-width:768px) and (max-width:991.90px){
    .employeeperk-descp p{
        font-size: 19px;
        line-height: 30px;
    }
    .employeeperk-box-main{
        gap: 20px;
    }
}
@media(min-width:481px) and (max-width:767.90px){
    .employeeperk-descp p{
        font-size: 18px;
        line-height: 30px;
    }
    .employeeperk-box-main{
        gap: 15px;
    }
}
@media(min-width:320px) and (max-width:480.90px){
    .employeeperk-descp p{
        font-size: 16px;
        line-height: 25px;
    }
    .employeeperk-box-main{
        gap: 15px;
    }
    .employeeperk-box-main > div{
        flex-basis: 47%;
        padding: 12px;
    }
}