.journey-content p{
    color: #6D6D6D;
    font-family: Light;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 25px;
    padding-right: 3rem;
}
.journey-content {
    display: flex;
}
.journey-content > div{
    flex: 1;
}
.journey-content .img-journey{
    position: relative;
}
.img-journey img{
    position: absolute;
    right: 0;
    bottom: -10rem;
}
.journey-main{
    border-radius: 40px;
    background: #F3F3F3; 
    padding: 2rem 2rem 5rem;
    margin: 6rem 0 10rem;
}
@media(min-width:768px) and (max-width:991.90px){
    .journey-content p{
        font-size: 16px;
        line-height: 24px;
    }
    .img-journey img{
        bottom: auto;
        top: 0rem;
    }
    .journey-main{
        margin: 3rem 0;
    }
}
@media(min-width:481px) and (max-width:767.90px){
    .journey-content p{
        font-size: 15px;
        line-height: 24px;
        padding-right: 0;
    }
    .img-journey img{
        bottom: auto;
        top: auto;
        position: relative;
        margin-top: 2rem;
    }
    .journey-main{
        margin: 3rem 0;
        padding: 2rem;
    }
    .journey-content {
        display: block;
    }
}
@media(min-width:320px) and (max-width:480.90px){
    .journey-content p{
        font-size: 14px;
        line-height: 22px;
        padding-right: 0;
    }
    .img-journey img{
        bottom: auto;
        top: auto;
        position: relative;
        margin-top: 2rem;
    }
    .journey-main{
        margin: 2rem 0;
        padding: 1.5rem;
    }
    .journey-content {
        display: block;
    }
}