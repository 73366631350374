.whatwelook-main{
    display: flex;
    margin-top: 4rem;
    align-items: flex-start;
}
.whatwelook-main > div{
    flex:1;
    padding: 25px;
    border-left: 1px solid #0098DB;
}
.whatwelook-main label{
    color: #000;
    font-family: SemiBold;
    font-size: 24px;
    line-height: 32px; 
    text-transform: capitalize;
}
.whatwelook-main p{
    color: #6D6D6D;
    font-family: Light;
    font-size: 18px;
    line-height: 27px;
}
.whatwelook-main div:nth-child(2n+1) {
    margin-top: 4rem;
}
@media(min-width:992px) and (max-width:1199.90px){
    .whatwelook-main label{
        font-size: 22px;
        line-height: 30px;
    }
    .whatwelook-main p{
        font-size: 17px;
        line-height: 25px;
    }
}
@media(min-width:768px) and (max-width:991.90px){
    .whatwelook-main label{
        font-size: 20px;
        line-height: 27px;
    }
    .whatwelook-main p{
        font-size: 16px;
        line-height: 24px;
    }
    .whatwelook-main p{
        font-size: 16px;
        line-height: 24px;
    }
    .whatwelook-main > div{
        padding: 15px;
    }
}
@media(min-width:481px) and (max-width:767.90px){
    .whatwelook-main label{
        font-size: 18px;
        line-height: 27px;
    }
    .whatwelook-main p{
        font-size: 15px;
        line-height: 24px;
    }
    .whatwelook-main p{
        font-size: 15px;
        line-height: 24px;
    }
    .whatwelook-main > div{
        padding: 15px;
        flex-basis: 50%;
        margin-top: 0 !important;
        margin-bottom: 2rem;
    }
    .whatwelook-main{
        margin-top: 2rem;
        flex-wrap: wrap;
        align-items: inherit;
    }
}
@media(min-width:320px) and (max-width:480.90px){
    .whatwelook-main label{
        font-size: 16px;
        line-height: 25px;
    }
    .whatwelook-main p{
        font-size: 14px;
        line-height: 22px;
    }
    .whatwelook-main p{
        font-size: 14px;
        line-height: 22px;
    }
    .whatwelook-main > div{
        padding: 15px;
        flex-basis: 50%;
        margin-top: 0 !important;
        margin-bottom: 2rem;
    }
    .whatwelook-main{
        margin-top: 2rem;
        flex-wrap: wrap;
        align-items: inherit;
    }
}