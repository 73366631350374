.PrivacyBodyHeading{
    width: fit-content;
    padding: 1rem 1.7rem;
    margin-top: -1.5rem;
    font-size: 2rem;
    font-weight: bold;
    /* background-color: rgba(8, 79, 130, 0.9); */
    color: #fff;
    background-image: linear-gradient(to right, rgba(17, 71, 103, .9), rgba(0, 195, 255, 0.9));
}

.PrivacyBodyText{
    box-shadow: 0rem 0rem .7rem #d8d8d8;
    border-radius: 1rem;
    margin: 2rem 0 3rem;
    padding: 1rem 2rem 3rem;
    font-size: 1rem;
    line-height: 2rem;
}

.PrivacyBodyText span{
    width: 100%;
    display: block;
}
.PrivacyBodyText span h6{ 
    display: inline-block;
    color: #fff;
    font-size: .85rem;
    font-weight: bold;
    background: #1f95d2;
    padding: .1rem .5rem;
    border-radius: 100%;
    width: 1.4rem;
    height: 1.2rem;
    margin-right: 5px;}

    @media(min-width:320px) and (max-width:575.90px){

        .PrivacyBodyHeading {
            font-size: 1.4rem;
            padding: .7rem 1.5rem;
        }
    }
 