.all-service-main{
    margin: 4rem 0;
}
.service-main-box p,.service-main-box li{
    color: #575757;
    font-family: Light;
    font-size: 18px;
    line-height: 25px; 
}
.service-main-box label{
    color: #000;
    font-family: SemiBold;
    font-size: 18px;
    line-height: 25px; 
}
.service-main-box ul{
    padding-left: 20px;
}
.breadcrum-sc{
    padding-left: 0;
}
.breadcrum-sc li, .breadcrum-sc a, .breadcrum-sc a:hover{
    color: #515151;
    font-family: Light;
    font-size: 18px;
    line-height: 34px;
    display: inline-block;
    text-decoration: none;
}
.breadcrum-sc li{
    color: #000;
    font-family: SemiBold;
    padding:0 10px;
    position: relative;
}
.breadcrum-sc a::after{
    content: "";
    background: url('data:image/svg+xml,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 320 512" height="12px" width="12px" xmlns="http://www.w3.org/2000/svg" style=" color: %23515151;"><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path></svg>');
    position: absolute;
    background-size: 100% 100%;
    width: 12px;
    height: 12px;
    right: -5px;
    top: 12px;
}
.about-banner-sc .banner-name a {
    font-size: 15px;
    color: #fff;
    text-decoration: none;
}
.feature-slider {
    border-radius: 30px;
    background: linear-gradient(144deg, #F1FBFF 20.92%, #EAF8FF 65.53%);
    padding: 4rem;
    margin: 3rem 0;
}
.feature-slider h3{
    color:  #0098DB;
    font-family: SemiBold;
    font-size: 42px;
    border-bottom: 1px solid #E4E4E4;
    padding-bottom: 25px;
    margin-bottom: 3rem;
}
.feature-main-box span{
    position: relative;
    color: #000;
    font-family: Bold;
    font-size: 62px;
    line-height: 42px;
    padding: 0 10px;
}
.feature-slider .slick-slide{
    padding: 0 1rem;
}
.feature-main-box span:after{
    content: "";
    background: #B8EAFF;
    width: 100%;
    height: 30px;
    position: absolute;
    bottom: 5px;
    left: 0;
    z-index: -1;
}
.feature-main-box h4{
    color: #000;
    font-family: SemiBold;
    font-size: 24px;
    line-height: 28px; 
    margin-top: 3rem;
}
.feature-main-box p{
    color: #575757;
    font-family: Light;
    font-size: 17px;
    line-height: 24px; 
}
.main-header.sub-heading h2{
    color: #000;
    font-family: SemiBold;
    font-size: 42px; 
    margin-bottom: 1rem;
}

@media(min-width:992px) and (max-width:1199.90px){
    .service-main-box p, .service-main-box li{
        font-size: 17px;
    }
    .feature-slider h3{
        font-size: 36px;
    }
    .feature-slider{
        padding: 3rem 4rem;
    }
}
@media(min-width:768px) and (max-width:991.90px){
    .service-main-box p, .service-main-box li{
        font-size: 17px;
    }
    .feature-slider h3{
        font-size: 34px;
    }
    .feature-slider{
        padding: 3rem 4rem;
    }
    .service-main-img {
        width: 45%;
        margin: 0 auto;
    }
    .feature-main-box h4{
        font-size: 21px;
        line-height: 25px;
        margin-top: 2rem;
    }
    .feature-main-box p{
        font-size: 17px;
    }
}
@media(min-width:481px) and (max-width:767.90px){
    .service-main-box p, .service-main-box li{
        font-size: 16px;
    }
    .feature-slider h3{
        font-size: 30px;
    }
    .feature-slider{
        padding: 2rem ;
    }
    .service-main-img {
        width: 60%;
        margin: 0 auto;
    }
    .feature-main-box h4{
        font-size: 21px;
        line-height: 25px;
        margin-top: 2rem;
    }
    .feature-main-box p{
        font-size: 17px;
    }
    .service-main-box {
        margin-top: 1rem;
    }
    .feature-slider .common-slider .slick-prev{
        left: -2rem;
    }
    .feature-slider .common-slider .slick-next{
        right: -2rem;
    }
}
@media(min-width:320px) and (max-width:480.90px){
    .service-main-box p, .service-main-box li{
        font-size: 15px;
    }
    .feature-slider h3{
        font-size: 25px;
    }
    .feature-slider{
        padding: 2rem ;
    }
    .service-main-img {
        width: 60%;
        margin: 0 auto;
    }
    .feature-main-box h4{
        font-size: 20px;
        line-height: 22px;
        margin-top: 2rem;
    }
    .feature-main-box p{
        font-size: 16px;
    }
    .service-main-box {
        margin-top: 1rem;
    }
    .feature-slider .common-slider .slick-prev{
        left: -2rem;
    }
    .feature-slider .common-slider .slick-next{
        right: -2rem;
    }
}