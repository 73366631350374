.job-application-container .content span {
    display: block;
    font-size: 24px;
    color: #575757;
    line-height: 34px;
    margin-top: 10px;
    font-family: Regular;
}

.job-application-container.Start {
    align-items: start !important;
}

.job-application-container .content {
    padding: 0;
    padding-left: 40px;
    padding-top: 50px;
}

.form-field textarea {
    resize: none;
    width: 100%;
    padding-bottom: 12px;
    border: 0;
    outline: 0;
    border-bottom: 1px solid #DCDCDC;
    color: #9B9B9B;
    font-size: 14px;
}
.form-field textarea::placeholder {
    color: #9B9B9B;
}

.form-field.captcha,
.captcha-container{
    display: flex;
    gap: 10px;
    align-items: center;
}
.job-application-sc .form-field{
    margin-bottom: 20px;
    position: relative;
}
.captcha-container span{
    color: #fff;
    background-color: #000;
    padding: 3px 10px;
    letter-spacing: 5px;
    font-style: italic;
    width: 120px;
    display: inline-block;
    text-align: center;
}
.captcha-container img {
    cursor: pointer;
}
#feedBackForm small{
    color: red;
  position: absolute;
  bottom: -16px;
  left: 0;
  font-size: 11px;
}
@media (max-width:991px){
    .job-application-container.Start {
        align-items: end !important;
      }
    .backdrop,
    .backdrop img {
        width: 100% !important;
    }
    .job-application-container .content {
        padding: 0;
    }
    .job-application-container .content span {
        font-size: 18px;
        line-height: 24px;
    }
  }
  
  @media (max-width:767px){

  }
  
  @media (max-width:575px){
    .job-application-container .content span {
        font-size: 16px;
        line-height: 22px;
    }
  }
  
  @media (max-width:480px){
    .form-field.captcha{
   flex-direction: column;
}


  }
  
  @media (max-width:360px){
      
  }



