.company-sc{
    margin:4rem 0;
}
.company-descp label{
    color: #000;
    font-family: Medium;
    font-size: 18px;
    line-height: 26px; 
}
.company-descp p{
    color:  #575757;
    font-family: Light;
    font-size: 18px;
    line-height: 26px;
}
.company-descp-right {
    display: flex;
    gap: 40px;
}
.company-descp-right img{
    margin-bottom: 30px;
}
.divSizeChange{
    display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 20px;
}

.divSizeChange div:first-child{
    /* width: 50%; */
    grid-row: 1 / 3;
}

.divSizeChange div:nth-child(2){
    float: left;

}
.divSizeChange div:nth-child(2) label{
    text-align: center;
    width: 100%;
}
.divSizeChange div:nth-child(3){
    float: left;
}
.divSizeChange div:nth-child(3) label{
    text-align: center;
    width: 100%;
}
.divSizeChange div:nth-child(3) p{
    text-align: center;
    width: 100%;
}

.divSizeChange div:nth-child(2) p{
    text-align: center;
    width: 100%;
    padding: 0 2rem;
  line-height: 1.4rem;
}

@media(min-width:992px) and (max-width:1199.90px){
    .company-descp p{
        font-size: 16px;
        line-height: 24px;
    }
}
@media(min-width:768px) and (max-width:991.90px){
    .company-descp p{
        font-size: 16px;
        line-height: 24px;
    }
}
@media(min-width:481px) and (max-width:767.90px){
    .company-descp p{
        font-size: 16px;
        line-height: 24px;
    }
    .company-descp-right > div{
        flex: 1;
    }
    .company-sc {
        margin: 2rem 0;
    }
}
@media(min-width:320px) and (max-width:480.90px){
    .company-descp p{
        font-size: 15px;
        line-height: 24px;
    }
    .company-descp-right {
        flex-wrap: wrap;
    }
    .company-sc {
        margin: 2rem 0;
    }
}

@media(min-width:320px) and (max-width:576.90px){

    .divSizeChange div:nth-child(2){
        flex: 100% !important;
        max-width: 100% !important;
    }
    .divSizeChange div:nth-child(3){
        flex: 100% !important;
        max-width: 100% !important;
    }
}

@media(min-width:320px) and (max-width:991.90px) {
    .divSizeChange{
        display: grid;
      grid-template-columns: repeat(1,1fr);
      gap: 20px;
    }
    
    .divSizeChange div:first-child{
        /* width: 50%; */
        grid-row: 1;
    }
}