.feedback-form-banner-sc{
    background:url(../../Images/FeedbackFormBanner.jpg) no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100vh;
}

@media(max-width:767px){
    .feedback-form-banner-sc{
        height: 100%;
    }
}