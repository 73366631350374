.our-client-main{
    padding: 2rem 0 5rem;
}
.our-client-logo ul li {
    display: inline-block;
    margin-right: 4rem;
    margin-top: 4rem;
    filter: grayscale(100%);
    transition: all 0.3s ease-in-out;
    width: 11.5%;
    cursor: pointer;
}
.our-client-logo ul li:hover{
    filter: grayscale(0%);
}
.our-client-logo ul {
    list-style: none;
    padding-left: 0;
}
.our-client-logo ul li:nth-child(6n){
    margin-right: 0;
}
@media(min-width:992px) and (max-width:1199.90px){
    .our-client-logo ul li{
        margin-right: 3rem;
    }
    .our-client-logo ul li:nth-child(6n) {
        margin-right: 0;
    }
}
@media(min-width:768px) and (max-width:991.90px){
    .our-client-logo ul li{
        margin-right: 2rem;
        width: 12.5%;
    }
    .our-client-logo ul li:nth-child(6n) {
        margin-right: 0;
    }
}
@media(min-width:481px) and (max-width:767.90px){
    .our-client-logo ul li,.our-client-logo ul li:nth-child(6n){
        margin-right: 2rem;
        width: 20%;
    }
    .our-client-logo ul li:nth-child(4n) {
        margin-right: 0;
    }
    .our-client-main {
        padding: 2rem 0 2rem;
    }
}
@media(min-width:481px) and (max-width:575.90px){
    .our-client-logo ul li, .our-client-logo ul li:nth-child(6n) {
        width: 19.5%;
    }
}
@media(min-width:320px) and (max-width:480.90px){
    .our-client-logo ul li, .our-client-logo ul li:nth-child(6n) {
        width: 47%;
        margin-right: 1rem;
        margin-top: 2rem;
    }
    .our-client-logo ul li:nth-child(2n) {
        margin-right: 0;
    }
    .our-client-main {
        padding: 2rem 0 2rem;
    }
}