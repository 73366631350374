.weare-main-sc{
    display: flex;
    align-items: center;
    margin: 4rem 0;
}
.we-are-main{
    padding-top: 5rem;
}
.weare-main-sc > div{
    flex:1;
}
.weare-main-sc label{
    color: #000;
    font-family: Light;
    font-size: 62px;
    line-height: 52px;
}
.weare-main-sc p{
    color: #000;
    font-family: Light;
    font-size: 22px;
    line-height: 26px; 
    margin-bottom: 0;
}
.weare-main-sc > div div:first-child{
    border-bottom:1px solid #000 ;
}
.weare-main-sc div div{ 
    padding: 50px 0 35px;
}
@media(min-width:992px) and (max-width:1199.90px){
    .weare-main-sc label{
        font-size: 50px;
    }
    .weare-main-sc p{
        font-size: 20px;
    }
}
@media(min-width:768px) and (max-width:991.90px){
    .weare-main-sc label{
        font-size: 40px;
    }
    .weare-main-sc p{
        font-size: 18px;
    }
    .weare-main-sc{
        margin: 2rem 0;
    }
}
@media(min-width:481px) and (max-width:767.90px){
    .weare-main-sc label{
        font-size: 35px;
        line-height: 40px;
    }
    .weare-main-sc p{
        font-size: 17px;
    }
    .we-are-main {
        padding-top: 2rem;
    }
    .weare-main-sc{
        margin: 2rem 0;
    }
    .weare-main-sc div div {
        padding: 35px 0 25px;
    }
}
@media(min-width:320px) and (max-width:480.90px){
    .weare-main-sc label{
        font-size: 30px;
        line-height: 35px;
    }
    .weare-main-sc p{
        font-size: 16px;
    }
    .we-are-main {
        padding-top: 1.8rem;
    }
    .weare-main-sc{
        margin: 2rem 0;
        flex-direction: column;
    }
    .weare-main-sc div div {
        padding:1rem  0;
    }
    .weare-main-sc > div {
        flex: 100%;
        width: 100%;
    }
    .weare-main-sc > div img {
        width: 60%;
        margin: 0 auto;
        display: block;
    }
}