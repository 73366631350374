.overview-box-main.product-overview-box div:first-child{
    flex-basis: 47%;
}
.overview-box-main > div:nth-child(odd){
    margin-top: 0;
}
.overview-box-main > div:nth-child(even){
    margin-top: 5rem;
}
.overview-box-main.product-overview-box{
    margin-top: 4rem;
}
@media(min-width:320px) and (max-width:767.90px){
    .overview-box-main > div:nth-child(odd){
        margin-top: 2rem;
    }
    .overview-box-main > div:nth-child(even){
        margin-top: 2rem;
    }
}