@font-face {
    font-family: Regular;
    src: url(./Font/Outfit-Regular.ttf);
    font-display: swap;
}
@font-face {
    font-family: Medium;
    src: url(./Font/Outfit-Medium.ttf);
    font-display: swap;
}
@font-face {
  font-family: SemiBold;
  src: url(./Font/Outfit-SemiBold.ttf);
  font-display: swap;
}
@font-face {
    font-family: Bold;
    src: url(./Font/Outfit-Bold.ttf);
    font-display: swap;
}
@font-face {
    font-family: Light;
    src: url(./Font/Outfit-Light.ttf);
    font-display: swap;
}
@font-face {
    font-family: 'HeadingFont';
    src: url(./Font/BaskOldFace.ttf);
    font-display: swap;
}
body{
background-color: #fff !important; font-family: Regular !important;
overflow-x: hidden;
}
