.banner-one-bg{
    background:url(./../../Images/banner-bg-one.jpg) no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    padding: 5rem 0 0rem;
}
.banner-two-bg{
    background:url(./../../Images/banner-bg-two.jpg) no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    padding: 5rem 0 0rem;
}
.banner-sc h1{
    color: #FFF;
    text-shadow: 3px 3px 13px #097AAD;
    font-family: HeadingFont;
    font-size: 50px;
}
.banner-inner{
    position: relative;
}
.banner-sc h1 span{
    color: #F00000; 
}
.banner-sc p{
    color: #FFF;
    font-family: Light;
    font-size: 18px;
}
.banner-content{
    padding: 10rem 0;
    z-index: 1;
    position: relative;
}
.banner-sc img {
    position: absolute;
    right: 0;
    top: 2rem;
    z-index: 5;
}
.desktop-slide-small,.tab-slide,.mobile-slide-big,.mobile-slide-middle,.mobile-slide-small{
    display:none;
}
@media(min-width:992px) and (max-width:1199.90px){
    .banner-sc h1{
        font-size: 40px;
    }
    .banner-sc img{
        width: 50%;
    }
    .banner-content{
        padding: 7rem 0;
    }
    .banner-sc p{
        font-size: 17px;
    }
    .desktop-slide-small{
        display:block;
    }
    .desktop-slide{
        display: none;
    }
}
@media(min-width:768px) and (max-width:991.90px){
    .banner-sc h1{
        font-size:35px;
    }
    .banner-sc img{
        width: 45%;
    }
    .banner-content{
        padding: 5rem 0;
    }
    .banner-sc p{
        font-size: 17px;
        width: 55%;
    }
    .banner-sc p br{
        display: none;
    }
    .tab-slide{
        display: block;
    }
    .desktop-slide{
        display: none;
    }
}
@media(min-width:576px) and (max-width:767px){
    .mobile-slide-big{
        display: block;
    }
    .desktop-slide{
        display: none;
    }
}
@media(min-width:481px) and (max-width:767.90px){
    .banner-sc h1{
        font-size:32px;
    }
    .banner-sc img {
        position: relative;
        right: auto;
        top: auto;
        left: auto;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        display: block;
        margin-bottom: 15px;
    }
    .banner-content{
        padding: 2rem 0;
    }
    .banner-sc p{
        font-size: 17px;
        width: 100%;
    }
    .banner-sc p br{
        display: none;
    }
    
}
@media(min-width:481px) and (max-width:575.90px){
    .mobile-slide-middle{
        display: block;
    }
    .desktop-slide{
        display: none;
    }
}
@media(min-width:320px) and (max-width:480px){
    .mobile-slide-small{
        display: block;
    }
    .desktop-slide{
        display: none;
    }
    .banner-sc img{
        width: 290px;
    }
}
@media(min-width:320px) and (max-width:480.90px){
    .banner-sc h1{
        font-size:30px;
    }
    .banner-sc img {
        position: relative;
        right: auto;
        top: auto;
        left: auto;
        margin: 0 auto;
        text-align: center;
        display: block;
        margin-bottom: 15px;
    }
    .banner-content{
        padding: 2rem 0;
    }
    .banner-sc p{
        font-size: 16px;
        width: 100%;
    }
    .banner-sc p br,.banner-sc h1 br{
        display: none;
    }
    
}