.career-form-banner-sc{
    background:url(../../Images/career-form-banner.png) no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100vh;
}


.banner-name h3{
    color: #FFF;
    font-family: Medium;
    font-size: 18px;
    line-height: 34px;
    border-bottom:1px solid #FFF ;
    margin-top: 50px;
}
.banner-description{
    color: #FFF;
    font-family: SemiBold;
    font-size: 54px;
    line-height: 66px;
    margin:4rem 0 8rem;
}

@media(min-width:992px) and (max-width:1199.90px){
    .banner-description {
        font-size: 43px;
        line-height: 50px;
        margin: 3rem 0 7rem;
    }
}
@media(min-width:768px) and (max-width:991.90px){
    .banner-description {
        font-size: 40px;
        line-height: 50px;
        margin: 3rem 0 7rem;
    }
    .banner-description br{
        display: none;
    }
}
@media(min-width:481px) and (max-width:767.90px){
    .banner-description {
        font-size: 35px;
        line-height: 40px;
        margin: 2rem 0 5rem;
    }
    .banner-name h3{
        margin-top: 15px;
    }
    .banner-description br{
        display: none;
    }
}
@media(min-width:320px) and (max-width:480.90px){
    .banner-description {
        font-size: 30px;
        line-height: 35px;
        margin: 2rem 0 5rem;
    }
    .banner-name h3{
        margin-top: 15px;
    }
    .banner-description br{
        display: none;
    }
}