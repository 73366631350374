.industry-box .main-header h2{
    font-size: 42px;
    margin-bottom:15px;
}
.industry-box .main-header{
    padding-left: 2rem;
}
.industry-box p{
    margin-bottom: 3rem;
}
.industry-box{
    margin: 8rem 0;
}
.industry-sc {
    border-radius: 90px;
    background: #EFF7FF;
    padding: 60px 80px;
    width: 80%;
    margin: 50px auto 0;
    display: flex;
    gap: 10px;
}
.industry-sc-inner p{
    display: none;
    color: #787878;
    font-family: Light;
    font-size: 16px;
    line-height: 22px;
    position: absolute;
    background: #fff;
    left: 0;
    top: 6rem;
    width: 100%;
    padding: 20px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.09);
}
.industry-sc-inner h3{
    color: #000;
    font-size: 18px;
    margin-top: 15px;
}
.industry-sc-inner:hover p{
   display: block;
}
.industry-sc-inner:hover {
    border-radius: 30px;
    background: #FFF;
    box-shadow: 0px 8px 34px 0px rgba(0, 0, 0, 0.09);
}
.industry-sc > div {
    flex: 1;
    padding: 20px;
    position: relative;
    z-index: 1;
}
@media(min-width:992px) and (max-width:1199.90px){
    .industry-sc{
        width: 100%;
    }
    .industry-box .main-header h2{
        font-size: 40px;
    }
    .industry-box{
        margin: 4rem 0;
    }
}
@media(min-width:768px) and (max-width:991.90px){
    .industry-sc {
        padding: 60px 30px;
        width: 100%;
    }
}
@media(min-width:481px) and (max-width:767.90px){
    .industry-sc{
        width: 100%;
        padding: 35px 10px;
        flex-wrap: wrap;
    }
    .industry-sc > div{
        flex: 0 48%;
    }
    .industry-sc-inner p{
        z-index: 1;
    }
    .industry-box{
        margin: 4rem 0;
    }
    .industry-build img {
        width: 45%;
        margin: 0 auto;
        display: block;
    }
    .industry-box .main-header {
        padding-left: 0;
    }
    .industry-sc-inner p{
        font-size: 15px;
    }
}
@media(min-width:320px) and (max-width:480.90px){
    .industry-sc{
        width: 100%;
        padding: 35px 10px;
        flex-wrap: wrap;
        margin: 10px auto 0;
    }
    .industry-sc > div{
        flex: 100%;
    }
    .industry-sc-inner h3{
        text-align: center;
    }
    .industry-sc-inner p{
        z-index: 1;
    }
    .industry-box{
        margin: 4rem 0;
    }
    .industry-build img {
        width: 60%;
        margin: 0 auto 1rem;
        display: block;
    }
    .industry-box .main-header {
        padding-left: 0;
    }
    .industry-sc-inner img{
        margin: 0 auto;
        display: block;
        text-align: center;
    }
    .industry-sc-inner p{
        font-size: 14px;
    }
}