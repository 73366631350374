.services-sc{
    margin-top: 4rem;
}
.common-slider .slick-prev, .common-slider .slick-next,.common-slider  .slick-next:hover, .common-slider  .slick-next:focus,.common-slider  .slick-prev:hover, .common-slider  .slick-prev:focus{
    color: #1970CA;
    width: 50px;
    height: 50px;
}
.common-slider .slick-prev{
    left: -4rem;
}
.common-slider .slick-next{
    right: -4rem;
}
.common-slider .slick-disabled,.common-slider .slick-disabled:hover,.common-slider .slick-disabled:focus {
    color: #ADADAD;
}
.services-main-sc ul{
    list-style: none;
    /* height: 480px; */
    overflow: auto;
    padding-left: 15px;
    padding-right: 35px;
    scrollbar-color: #3366CC #CFCFCF;
    scrollbar-width: thin;
    padding-top: 15px;
    padding-bottom: 15px;
}
.services-main-sc ul::-webkit-scrollbar{
    scrollbar-width: none;
}
.services-main-sc ul::-webkit-scrollbar-track {
    background: #CFCFCF;
}
.services-main-sc ul::-webkit-scrollbar {
    height: 7px;
    width: 7px;
    background: #3366CC;
}

.services-main-sc ul::-webkit-scrollbar-thumb {
    background: #3366CC;
 
}
.services-main-sc .slick-list{
    background: url(./../../Images/sevices-bg.png) no-repeat;
    background-size: auto;
    background-size: 100% 100%;
}

.services-main-sc li{
    color: #575757;
    font-family: Medium;
    font-size: 18px;
    line-height: normal; 
    padding: 16px 18px; 
    cursor: pointer;
}
.services-main-sc li.active{
   color: #3366CC;
   box-shadow: 3px 9px 23px 0px rgba(0, 0, 0, 0.12);
   cursor: unset;
}
.services-main-sc > div:last-child {
   vertical-align: top;
   display: inline-block;
   width: 64%;
   padding: 0 50px;
}
.services-main-sc > div:first-child {
    vertical-align: middle;
    display: inline-block;
    width: 35%;
}
.services-main-sc{
    float: left;
    width:100%;
    margin-top: 50px;
    
}

.slider-inner{
    width: 100%;
    height: 100%;
    padding: 40px;
    
}
.slider-inner h3{
    color: #FFF;
    font-family: Medium;
    font-size: 24px;
    line-height: normal; 
    margin-bottom: 24px;
}
.slider-inner p{
    color: #FFF;
    font-family: Light;
    font-size: 16px;
    line-height: normal; 
}
.services-main-sc .slick-slider {
    width: 90%;
    margin: 0 auto;
}
.white-common-btn,.white-common-btn:hover{
    color: #1970CA;
    background-color: #FFF;
}
.services-main-sc .white-common-btn{
    margin-top: 20px;
    display: block;
    width: fit-content;
}
@media(min-width:992px) and (max-width:1199.90px){
    .services-main-sc li{
        font-size: 16px;
        padding: 10px 18px;
    }
    .slider-inner h3{
        font-size: 22px;
    }
    .slider-inner p{
        font-size: 15px;
    }
    .services-main-sc .slick-list{
        background-size: 100% 100%;
    }
}
@media(min-width:768px) and (max-width:991.90px){
    .services-main-sc li{
        font-size: 15px;
        padding: 10px 10px;
    }
    .slider-inner h3{
        font-size: 20px;
    }
    .slider-inner p{
        font-size: 15px;
    }
    .services-main-sc .slick-list{
        background-size: 100% 100%;
    }
    .services-main-sc > div:last-child{
        padding: 0 10px;
    }
}
@media(min-width:320px) and (max-width:767.90px){
    .services-main-sc > div:first-child{
        display: none;
    }
    .slider-inner h3{
        font-size: 20px;
    }
    .slider-inner p{
        font-size: 15px;
    }
    .services-main-sc .slick-list{
        background-size: 100% 100%;
    }
    .services-main-sc > div:last-child{
        padding: 0 10px;
        width: 100%;
    }
    .common-slider .slick-prev, .common-slider .slick-next, .common-slider .slick-next:hover, .common-slider .slick-next:focus, .common-slider .slick-prev:hover, .common-slider .slick-prev:focus{
        width: 30px;
        height: 30px;
    }
    .common-slider .slick-next {
        right: 0;
        background: #fff !important;
        z-index: 1;
        border-radius: 13px 0 0 13px;
    }
    .common-slider .slick-prev {
        left: 0;
        background: #fff !important;
        z-index: 1;
        border-radius: 0 13px 13px 0;
    }
    .slider-inner{
        padding: 10px;
    }
    .services-main-sc .slick-slider{
        width: 100%;
    }
}